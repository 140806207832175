import React from 'react';
import './styles/globals.css'
import './App.css';
import { useSelector } from "react-redux";
import { RootState, store } from './redux/store';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom';
import { useTheme } from '@mui/material';
import i18n from './i18n'; // Import i18n configuration
import { TypeProvider } from './contexts/TypeContext';

const HomeScreen = React.lazy(() => import('./screens/HomeScreen'));
const AuthScreen = React.lazy(() => import('./screens/Auth/AuthScreen'));
const LoadingScreen = React.lazy(() => import('./screens/LoadingScreen'));
const StoreScreen = React.lazy(() => import('./screens/Dashboard/SingleStore'));
const Dashboard = React.lazy(() => import('./screens/Dashboard/Dashboard'));
const Settings = React.lazy(() => import('./screens/Settings/settings'));
const CreateMerchant = React.lazy(() => import('./screens/CreateMerchant/CreateMerchant'));
const SignupVerify = React.lazy(() => import('./screens/Auth/SignupVerify'));
const PasswordResetVerify = React.lazy(() => import('./screens/Auth/ForgotPassword'));
const ContactUs = React.lazy(() => import('./screens/ContactUs/ContactUs'));
const Payout = React.lazy(() => import('./screens/Payout/Payout'));
const Statement = React.lazy(() => import('./screens/Statement/Statement'));
const Integration = React.lazy(() => import('./screens/Integration/Intergration'));
const Invoice = React.lazy(() => import('./screens/Invoice/Invoice'));
const PaymentLinks = React.lazy(() => import('./screens/PaymentLinks/PaymentLinks'));
const PaymentLinkTransactions = React.lazy(() => import('./screens/PaymentLinks/PaymentLinkTransactions'));
const CreatePaymentLink = React.lazy(() => import('./screens/PaymentLinks/CreatePaymentLink'));
const UserManagement = React.lazy(() => import('./screens/UserManagement/UserManagement'));
const Intercom = React.lazy(() => import('./screens/Intercom/Intercom'));
const INTERCOM_APP_ID: string = process.env.REACT_APP_INTERCOM_APP_ID ?? ""

function App() {
  const { auth } = useSelector((root: RootState) => root.user);
  const [requestMade, setRequestMade] = React.useState(false);
  const theme = useTheme();
  const { dispatch } = store;
  
  React.useEffect(() => {
    dispatch.user.isAuthenticatedAsync().then(() => {
      setRequestMade(true)
    }).catch(() => {
      setRequestMade(true)
    })
  }, [])

  const authenticatedRoute = (component: JSX.Element) => {
    return auth ? component : <Navigate to="/" replace />;
  }
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Intercom requestMade={requestMade} />
      <BrowserRouter>
      <TypeProvider>
        <React.Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route
              path='/'
              element={requestMade ?
                (auth ? <HomeScreen /> : <AuthScreen />)
                : <LoadingScreen />}>
              <Route path='' element={auth ? <Dashboard /> : <></>} />
              <Route path='dashboard/:id' element={auth ? <Dashboard /> : <></>} />
              <Route path='settings' element={authenticatedRoute(<Settings />)} />
              <Route path="merchant/create" element={authenticatedRoute(<CreateMerchant />)} />
              <Route path="merchant/:id" element={authenticatedRoute(<CreateMerchant />)} />
              <Route path="store/:id" element={authenticatedRoute(<StoreScreen />)} />
              <Route path="payout/:id" element={authenticatedRoute(<Payout />)} />
              <Route path="statement/" element={authenticatedRoute(<Statement />)} />
              <Route path="store/:id/invoice/" element={authenticatedRoute(<Invoice />)} />
              <Route path="store/:id/integration/" element={authenticatedRoute(<Integration />)} />
              <Route path="store/:id/paymentlinks/" element={authenticatedRoute(<PaymentLinks />)} />
              <Route path="store/:id/paymentlink/create/" element={authenticatedRoute(<CreatePaymentLink />)} />
              <Route path="store/:id/paymentlink/:payment_link_id/transactions/" element={authenticatedRoute(<PaymentLinkTransactions />)} />
              <Route path="user/management/:id" element={authenticatedRoute(<UserManagement />)} />
              <Route path="contact-us/" element={authenticatedRoute(<ContactUs />)} />
              <Route
                path="*"
                element={
                  <Navigate to="/" replace />
                }
              />
            </Route>
            <Route path="/signup/verify" element={<SignupVerify />} />
            <Route path="/password/reset" element={<PasswordResetVerify />} />
          </Routes>
        </React.Suspense>
        </TypeProvider>
      </BrowserRouter>
    </IntercomProvider>
  );
}

export default App;
