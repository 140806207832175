import { createRef, useState } from "react";
import { toast, TypeOptions } from 'react-toastify';
import moment from "moment";


export const toastRef = createRef();

export class GenUtils {
  static async setToSyncStorage(key: string, value: string) {
    await localStorage.setItem(key, value);
  }

  static getFromSyncStorage(key: string) {
    if (key) {
      return localStorage.getItem(key);
    }
  }

  static clear() {
    return localStorage.clear();
  }

  static async removeFromSyncStorage(key: string) {
    await localStorage.removeItem(key);
  }

  static isNameValid(val: string) {
    return /^[a-zA-Z ]*$/.test(val) || /[\u0600-\u06FF\u0750-\u077F]/.test(val);
  }

  static isAfterToday(date: Date) {
    return moment(date).isAfter(new Date());
  }

  static formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }

  static showToast(text: string, type: TypeOptions) {
    toast(text, { type })

  }

  static useFileState(fileUrl: string | undefined): [string, React.Dispatch<React.SetStateAction<string>>] {
    const fileName = fileUrl?.includes('http') ? fileUrl.substring(fileUrl.lastIndexOf('/') + 1) : '';
    const [state, setState] = useState(fileName || '');
    return [state, setState];
}

}
