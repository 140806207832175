export default {
  // Local Storage
  ONBOARDING_DONE: "ONBOARDING_DONE",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  LANGUAGE: "LANGUAGE",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  USER_ID: "USER_ID",
  GOOGLE_PACES_API_BASE_URL: "https://maps.googleapis.com/maps/api",
  // Errors
  INVALID_REFRESH_TOKEN: "INVALID_REFRESH_TOKEN",
  UNAUTHORIZED: "UNAUTHORIZED",
  PROVIDER: "merchant",
  CLIENT_ID: '1041808821242-l0o7km6klq5t8hjcui3a4cqe5l0ph0fs.apps.googleusercontent.com',

  // Snippet Types
  TEXT: "Text",
  DATE: "Date",

  // Date Picker Types
  GREGORIAN: "Gregorian",
  HIJRI: "Hijri",
  GOOGLE_MAPS_API_KEY: "AIzaSyDVWqaOxUtds5e2z9OBWf79q5IASU7uBIs",
  // Date Filters
  ALL: "allTime",
  LAST_MONTH: "monthly",
  WEEK: "weekly",
  ONE_WAY: "ONE_WAY",
  UNPAID: "Unpaid",
  PENDING: "Pending",
  SCHEDULED: "Scheduled",
  STARTED: "Started",
  ROUND_TRIP: "ROUND_TRIP",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  UNPAID_ORDER: "UNPAID_ORDER",
  ARABIC_TRANSLATED_MONTHS: [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "اغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ],

  DATE_FORMAT: "YYYY/MM/DD, hh:mm a",
  DATE_FORMAT_SHOW: "DD/M/YYYY",

  // Transaction Status
  FAILURE: "Failure",
  SUCCESS: "Success",

  // Invoice Date Filters
  LAST_7_DAYS: "LAST_7_DAYS",
  LAST_30_DAYS: "LAST_30_DAYS",
  LAST_90_DAYS: "LAST_90_DAYS",
  CUSTOM: "CUSTOM",

  // Date range
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE",

  // Toast Type
  ERROR: "ERROR",
  NORMAL: "NORMAL",
  BACKEND_URL: process.env.REACT_APP_BACKEND ?? `https://${process.env.REACT_APP_DEPLOY_VARIANT}backend.${process.env.REACT_APP_COUNTRY_CODE?.toLowerCase()}.amwal.tech`,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
  PAY_URL: process.env.REACT_APP_PAY_URL ?? `https://${process.env.REACT_APP_DEPLOY_VARIANT}pay.${process.env.REACT_APP_COUNTRY_CODE?.toLowerCase()}.amwal.tech`
};
