import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface TypeContextType {
    type: 'SANDBOX' | 'PRODUCTION';
    setType: (type: 'SANDBOX' | 'PRODUCTION') => void;
}

const TypeContext = createContext<TypeContextType | undefined>(undefined);

export const useType = () => {
    const context = useContext(TypeContext);
    if (!context) {
        throw new Error('useType must be used within a TypeProvider');
    }
    return context;
};

interface TypeProviderProps {
    children: ReactNode;
}

export const TypeProvider: React.FC<TypeProviderProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedStore } = useSelector((root: RootState) => root.user);
    const [type, setType] = useState<'SANDBOX' | 'PRODUCTION'>('PRODUCTION');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const typeFromURL = params.get('type');
        if (typeFromURL === 'PRODUCTION' || typeFromURL === 'SANDBOX') {
            setType(typeFromURL);
        } else if (selectedStore && !selectedStore.is_activated) {
            setType('SANDBOX');
        }
    }, [location, selectedStore]);

    const changeType = (newType: 'SANDBOX' | 'PRODUCTION') => {
        const params = new URLSearchParams(location.search);
        params.set('type', newType);
        navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
        setType(newType);
    };

    return (
        <TypeContext.Provider value={{ type, setType: changeType }}>
            {children}
        </TypeContext.Provider>
    );
};
