import axios, { AxiosRequestConfig } from "axios";

import { GenUtils } from "../constants/GenUtils";
import Constants from "../constants/Constants";
export const apiClient = axios.create({
  baseURL: Constants.BACKEND_URL,
});

apiClient.interceptors.request.use(
  async (config) => {
    const token = await GenUtils.getFromSyncStorage(Constants.ACCESS_TOKEN);
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${token}`,
        platform: `${Constants.PROVIDER}`,
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      GenUtils.clear();
    }
    return Promise.reject(
      error.response.data.detail || error.response.data.non_field_errors || error.response.data
    );
  }
);

class QueryBaseService {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  static get(path: string, config?: AxiosRequestConfig) {
    return apiClient.get(path, config);
  }

  static post(path: string, body: any, config?: AxiosRequestConfig) {
    return apiClient.post(path, body, config);
  }

  static delete(path: string, config?: AxiosRequestConfig) {
    return apiClient.delete(path, config);
  }

  static patch(path: string, body: any, config?: AxiosRequestConfig) {
    return apiClient.patch(path, body, config);
  }

  static put(path: string, body: any, config?: AxiosRequestConfig) {
    return apiClient.put(path, body, config);
  }
}

export default QueryBaseService;
