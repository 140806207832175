import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { GenUtils } from './constants/GenUtils';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = GenUtils.getFromSyncStorage('theme')

const isDarkTheme = theme ? theme === 'dark' : false;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    secondary: {
      light: '#5dec49',
      main: '#03b807',
      dark: '#5dec49',
      contrastText: '#fff',
    },
    background: {
      paper: '#000016',
      default: '#000016',
    },
  },
})

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#1e212a',
      main: '#1e212a',
      dark: '#002164',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5dec49',
      main: '#03b807',
      dark: '#008600',
      contrastText: '#fff',
    },
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
  },
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <CssBaseline />
      <Provider store={store}>
        <ToastContainer />
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
